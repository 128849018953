<!--Complemento de los Menus -->
<template>
    <v-row class="black" no-gutters>
        <v-col cols="12"  class="black" >
        <!--Inicio del toolbar si es escritorio-->
        <v-toolbar
            elevation="0"
            class="menugrande black"
        >
            <img
                :src="require('../assets/beckslogo3.png')"
                width="150px"
                alt="Logo Cerveza Beck´s"
                title="Logo Cerveza Beck´s"
                class="ml-15"
            />
            <v-spacer></v-spacer>
            <v-btn
                v-for="(item, i) in items"
                :key="i"
                small
                text
                rounded
                :class="over == i ? 'becks--text' : 'white--text'"
                @mouseover="over= i"
                @mouseleave="over = -1"
                @click="$router.push({name: item.where})"
                :onclick="'eventoBecks(\''+item.action+'\',\'Clic\')'"
            >
                {{ item.title }}
            </v-btn>
            <v-btn
                class="my-5 black becks--text mx-auto"
                rounded
                large
                min-width="200"
                @click="closeSesion()"
            >
                CERRAR SESIÓN
            </v-btn>
            <v-btn color="becks"  elevation="2" class="ma-5" max-width="" rounded @click = "$router.push({name: 'AgregarUnaFoto'})">
                PARTICIPA
            </v-btn>
            
        </v-toolbar>
            <!-- Inicio del app bar si es mobile-->
            <v-toolbar
                color="black"
                class="d-md-none"
            >   
                <img
                    :src="require('../assets/beckslogo3.png')"
                    width="150px"
                    alt="Logo Cerveza Beck´s"
                    title="Logo Cerveza Beck´s"
                    class="ml-15"
                />
                <v-spacer></v-spacer>
                <v-app-bar-nav-icon   color="white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
            </v-toolbar>
        </v-col>
            <v-navigation-drawer
                v-if="drawer"
                v-model="drawer"
                right
                class="becks"
                style="z-index: 5"
                absolute
                temporary
            >
                <v-list-item>
                    <v-spacer/>
                    <v-btn
                        icon
                        color="black"
                        class="mr-n3 mb-n2"
                        @click="drawer = !drawer"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-list-item>
                <v-list-item
                    v-model="group"
                    v-for="(item, i) in items"
                    :key="i"
                >
                    <v-list-item-title @click="$router.push({name: item.where})" :onclick="'eventoBecks(\''+item.action+'\',\'Clic\')'">{{ item.title }}</v-list-item-title>
                </v-list-item>
                <v-list-item>
                    <v-btn
                        class="my-5 black becks--text mx-auto"
                        rounded
                        large
                        min-width="200"
                        @click="closeSesion()"
                    >
                        CERRAR SESIÓN
                    </v-btn>
                </v-list-item>
                <v-list-item>
                    <v-btn
                        class="my-5 black becks--text mx-auto"
                        rounded
                        large
                        min-width="200"
                        @click="redirigir()"
                    >
                        PARTICIPA
                    </v-btn>
                </v-list-item>
            </v-navigation-drawer>
    </v-row>
</template>

<script>
import axios from 'axios'

export default {
    name: 'menus',
    props:[
        'fondo',
        'fondo2'
    ],
    data: () => ({
            //Elementos del menu con Texto a mostrar, A que pagina manda y que Label manda al método de recolección de datos
            items: [
                { title: 'INICIO', where: 'AgregarUnaFoto', action: 'Inicio- Menú'},
                { title: 'GALERÍA', where: 'Galeria', action: 'Galeria- Menú'},
                //{ title: 'EVENTOS',where: 'Fiestas' },
                { title: 'PLAYLIST',where: 'Encuesta', action: 'Playlist- Menú'},
                //{ title: 'FAQ\'S', where: 'FAQs' },
                { title: 'SOBRE BECK\'S', where: 'Infobecks', action: 'Sobre Becks- Menú'},
                { title: 'MI PERFIL', where: 'Participaciones', action: 'Mi perfil- Menú'}
            ],
            drawer: false,
            group: null,
            over: -1
        }),
    watch: {
        group () {
            this.drawer = false
        },
    },
    methods:{
        redirigir(){
            this.$router.push({name: 'AgregarUnaFoto'})
        },
        closeSesion(){
            var vm = this
            axios
                .post("close_sesion.php", {

            }).then(function (response){
                console.log(response.data.sesion)

                if(response.data.sesion == 'cookie_borrada'){
                    vm.$router.push({name: 'Edad'})
                    //window.location.replace("/Edad");
                }
            }).catch(
                    error => console.log(error)
            )
        },
    }
}
</script>
<style  scoped>
    .menugrande{
        display: block;
    }
    @media (max-width: 960px){
        .menugrande{
        display: none;
    }
    }
</style>
